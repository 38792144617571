import React, { Component,Suspense, lazy, useEffect, useState } from 'react';
import { Switch, Route, Redirect,useLocation } from 'react-router-dom';

import Spinner from '../app/shared/Spinner';
import Sidebar from './shared/Sidebar';



const Menu = lazy(() => import('./menu/Menu'));
const User = lazy(() => import('./user/User'));
const Media = lazy(() => import('./media/Media'));
const Webpage = lazy(() => import('./webpage/Webpage'));
const Administrateurs = lazy(() => import('./administrateurs/Administrateurs'));
const Groupes = lazy(() => import('./groupes/Groupes'));
const Parametres = lazy(() => import('./parametres/Parametres'));

const Site = lazy(() => import('./editeur/Site'));

const Editeur = lazy(() => import('./editeur/Editeur'));


const Login = lazy(() => import('./user-pages/Login'));






//*ROUTES DE L'APPLICATION
const AppRoutes  = (props)=> {


  const [isLogined, setIsLogined] = useState(null);
  const [load, setLoad] = useState(false);

  const location = useLocation();
 
  //*function : pour connaitre le path
  function isPathActive(path) {

 
    //return <h1>Path is: {location.pathname}</h1>;

  
    return location.pathname.startsWith(path);
  }

   useEffect(()=>{



    //*ON CHECK SI L'UTILISATEUR EST CONNECTÉ OU NON 
    let isLogined=false;
    if(localStorage.getItem("isLogined")){
      isLogined=true;
    }
  
    setIsLogined(isLogined);
    setLoad(true);


   },[]);
 

    if(load){


    //*SI L'UTILISATEUR N'EST PAS CONNECTÉ
    if (!isLogined) {
      return(
      
        <Suspense fallback={<Spinner/>}>
            <Switch>
              <Route path="/login" component={ Login } />
              <Redirect to="/login" />
            </Switch>

        </Suspense>
      )

    }else{ //*SI L'UTILISATEUR EST CONNECTE

      return (
        
    
        
          <Suspense fallback={<Spinner/>}>
            
          <Switch>

         

        
          <div className="main-panel" style={{
            marginLeft:260,
          }}>
            
         
              {/**REDIRECTION DE LOGIN VERS LE SITE , la redirection se fais dans le component*/}
              <Route path="/login" component={ Login } />

              
              {/***ROUTES RESERVES UNIQUEMENT POUR LES ADMINISTRATEURS*/}
              { (localStorage.getItem("statutAdmin")==="true") &&
                  <>

                      {/**ON METS LA SIDEBAR UNIQUEMENT POUR LA PARTIE ADMIN*/}
                      { isPathActive("/admin")  &&
                                <Sidebar/>    
                        }
                      <Route exact path="/admin" component={Webpage} /> {/**ON REDIRIGE LA PAGE ADMIN SUR LES PAGES WEB */}
                      <Route exact path="/admin/menu" component={ Menu } />
                      <Route exact path="/admin/user" component={ User } />
                      <Route exact path="/admin/media" component={ Media } />
                      <Route exact path="/admin/pages" component={ Webpage } />
                      <Route exact path="/admin/parametres" component={ Parametres } />
                      <Route exact path="/admin/administrateurs" component={ Administrateurs } />
                      <Route exact path="/admin/groupes" component={ Groupes } />
           

                  </>
              }
           
            
            <div style={{ marginLeft:-260,}}>
               {/**POUR TOUT LES MEMBRES, MAIS VERIFICATION DES DROITS DANS LE COMPONENT *****/}
              <Route exact path="/admin/editeur*" component={ Editeur } />
               {/**POUR TOUT LES MEMBRES, MAIS VERIFICATION DES DROITS DANS LE COMPONENT*****/}
              <Route exact path="/site*" component={ Site } />

              {/**ON REDIRIGE PAR DEFAUT SUR LE SITE   */}
                
         
                 <Route exact path="/" component={ Site } />
              

            
            </div>

      
          </div>

          
      
        

      
            {/**ON REDIRIGE AU DEPART SUR LE SITE****/}
            <Redirect to="/site" />
          </Switch>
        </Suspense>
       
      );
    }
  }else{
    return (
      <div>
           
      </div>
    )
  }
  
}

export default AppRoutes;